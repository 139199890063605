
    import {computed, defineComponent, onMounted, reactive, toRefs, ref} from 'vue';
    import ApiUser from "@/request/apis/api_user";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import apiMember from "@/request/apis/api_member";
    import {appTitle} from "@/utils/config"

    interface IState {
        searches: any,
        typeData:any,
        total: any,
        list: any,
        treeData: any,
        platform_cate: any,
        title: any,
        visible: any,
        orderData: any,
        loading:any
    }


    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                searches: {
                    shop_name: '',
                    goods_name: '',
                    type: '',
                    platform_cate_id: '',
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize
                },
                total: 0,
                typeData:[],
                platform_cate: [],
                list: [],
                treeData: [],
                title: "",
                visible: false,
                orderData: '',
                loading:false,
            })
            const columns = [
                {
                    title: '订单编号',
                    dataIndex: 'orderId',
                    key: 'orderId',
                },
                {
                    title: '商户信息',
                    dataIndex: 'shop',
                    key: 'shop',
                },
                {
                    title: '订单金额',
                    dataIndex: 'orderMoney',
                    key: 'orderMoney',
                },
                {
                    title: '会员信息',
                    dataIndex: 'member',
                    key: 'member',
                },
                {
                    title: '商品信息',
                    dataIndex: 'information',
                    key: 'information',
                },
                {
                    title: '订单状态',
                    key: 'status',
                    dataIndex: 'status',
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150,
                    align:'center'
                },
            ];

            const getList = async () => {
                // state.loading = true
                state.list = []
                state.total =0
                const params = {...state.searches}
                const res = await ApiUser.OrderLists(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.total = data.count
                    state.list = data.lists
                    console.log(state.list)
                }
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));
            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };


            const OnSearch = () => {
                state.searches.page_size = PageUtil.pageSize
                state.searches.page_no = 1
                getList()
            }

            const OnReset = () => {
                state.searches.shop_name = ''
                state.searches.goods_name = ''
                state.searches.platform_cate_id = ''
                state.platform_cate = []
                state.searches.page_no = PageUtil.pageNo
                state.searches.page_size = PageUtil.pageSize
                getList()
            }


            const callback = (key) => {
                state.list = []
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                state.searches.type = key
                getList()
            }

            const getDetail = async(val) => {
                const params = {id:val.id}
                const res = await apiMember.OrderDetail(params)
                if (res) {
                    if (res.error_code === 0) {
                        state.orderData = res.data
                        state.visible = true
                        state.title = appTitle + '平台订单'
                    }
                }
            }

            const gettype = async () => {
                const params = {}
                const res = await apiMember.OrderStatistics(params)
                if (res) {
                    if (res.error_code === 0) {
                        state.typeData = res.data
                    }
                }
            }


            onMounted(() => {
                getList()
                gettype()
            })

            return {

                ...toRefs(state),
                getList,
                print,
                callback,
                gettype,
                getDetail,
                OnReset,
                columns,
                OnSearch,
                pagination,
                handleTableChange
            }
        }
    });
